import React from 'react'

const Rooms = () => {
  return (
    <div className="max-w-[1400px] h-[500px] bg-blue-100 mx-auto my-20 pt-16 lg:mb-[20%] md:mb-[35%] px-4 grid lg:grid-cols-3 gap-4">
      <div className="relative col-span-2 lg:top-20 lg:col-span-1">
        <h3 className="text-2xl font-bold">What we are here for</h3>
        <p className="pt-4">
          We specialize in residential and commercial painting services,
          including interior, exterior, color consultation, and wallpaper
          installation. Transforming spaces with attention to detail and
          personalized artistry.
        </p>
      </div>

      <div className="grid grid-cols-2 col-span-2 gap-2">
        <img
          className="object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1515104882246-521e5ba18f5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDd8fHBhaW50aW5nJTIwd2FsbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
          alt="/"
        />
        <img
          className="object-cover w-full h-full row-span-2"
          src="https://images.unsplash.com/photo-1542772144-515ddfae17e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTN8fHBhaW50aW5nJTIwd2FsbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
          alt="/"
        />
        <img
          className="object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1529722155810-17303d209942?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fHBhaW50aW5nJTIwd2FsbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
          alt="/"
        />
      </div>
    </div>
  )
}

export default Rooms
