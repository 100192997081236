import React from 'react';

const Plan = () => {
  return (
    <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>
      {/* Left Side */}
      <div className='grid grid-cols-2 grid-rows-6 h-[80vh]'>
        <img
          className='object-cover w-full h-full row-span-3 p-2'
          src='https://images.unsplash.com/photo-1614194076674-0598e6dd5703?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2UlMjBwYWludGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='/'
        />
        <img
          className='object-cover w-full h-full row-span-2 p-2'
          src='https://images.unsplash.com/photo-1574359411659-15573a27fd0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aG91c2UlMjBwYWludGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='/'
        />
        <img
          className='object-cover w-full h-full row-span-2 p-2'
          src='https://images.unsplash.com/photo-1615646225851-8c00598b19c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fHBhaW50aW5nJTIwd2FsbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='/'
        />
        <img
          className='object-cover w-full h-full row-span-3 p-2'
          src='https://images.unsplash.com/photo-1494435845424-47a556728b93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTJ8fHBhaW50aW5nJTIwd2FsbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='/'
        />
        <img
          className='object-cover w-full h-full row-span-2 p-2'
          src='https://images.unsplash.com/photo-1505909182942-e2f09aee3e89?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fHBhaW50aW5nJTIwd2FsbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='/'
        />
      </div>
      {/* Right Side */}
      <div className='flex flex-col justify-center h-full'>
        <h3 className='text-5xl font-bold md:text-6xl'>Book us for painting</h3>
        <p className='py-6 text-2xl'>
          We provide the best painting service in Nepal.
        </p>
        <p className='pb-6'>
        With expertise in both residential and commercial painting, we offer a wide range of services, including interior and exterior painting, color consultation, and wallpaper installation. Our attention to detail and personalized approach ensure transformative results for your space.
        </p>
        <div>
          <button className='w-32 mr-4 border-black hover:shadow-xl'>
            Learn More
          </button>
          <button className='w-32 text-white bg-black border-black hover:shadow-xl'>Book Us</button>
        </div>
      </div>
    </div>
  );
};

export default Plan;
