import React from 'react';

const Hero = () => {
  return (
    <div className='w-full h-screen'>
      <img
        className='top-0 left-0 object-cover w-full h-screen'
        src='https://images.unsplash.com/photo-1511822148790-e7b58ba14c72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1427&q=80'
        alt='/'
      />
      <div className='absolute top-0 left-0 w-full h-screen bg-black/30' />
      <div className='absolute top-0 flex flex-col justify-center w-full h-full text-white'>
        <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
          <p>Brushed Elegance</p>
          <h1 className='text-5xl font-bold md:text-7xl drop-shadow-2xl'>
          Revitalize. Renew. Reimagine.
          </h1>
          <p className='max-w-[800px] drop-shadow-2xl py-2 my-3 text-md'>
            Welcome to Bhimeshwor Painting, your premier destination for exceptional painting services. With our team of highly skilled painters and a commitment to quality craftsmanship, we bring life and beauty to residential and commercial spaces. Whether you're looking to transform your home, office, or any other property, we're here to provide you with top-notch painting solutions tailored to your needs.
          </p>
          <button className='w-32 text-black bg-white '>Reserve us</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
